body {
  background: #13161C !important;
}

.text-dark-light {
  color: #9da1a6 !important;
  background: linear-gradient(to right, #FFC500 10%, #75ff75 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-light-dark {
  color: #aaa;
}

.navbar .navbar-brand img {
  width: 140px;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* carousel wrapper */

.carousel-wrapper .carousel {
  padding: 5rem;
  background-color: black;
  color: #fff;
  height: 100vh;
  width: 100vh;
}

.service-wrapper .carousel .box {
  text-align: center;
  background-color: lightgreen;
  height: 300px;
}



.service-wrapper .card {
  border-radius: 20px !important;
  overflow: hidden;
  height: 400px;
}

.service-wrapper .card img {
  height: 400px;
  object-fit: cover;
  width: 100%
}

.service-wrapper .card:after {
  content: "";
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, transparent 0, transparent 30%, #000);
  opacity: 0.8;
}

.service-wrapper .card .card-content {
  border-radius: 6px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  padding: 10px;
  height: 85px;
  background: linear-gradient(to top, #000000 30%, #75ff7500 100%);
  padding-top: 34px;
  padding-bottom: 85px;
}

.service-wrapper .card .card-content h5 {
  font-size: 1.5rem;
}

.service-wrapper  .cursive {
  font-size: 24px;
  font-family: 'Dancing Script', sans-serif;
}


.service-wrapper h1 {
    position: relative;
      z-index: 1;
      color: #fff;
      font-family: "DM Sans", sans-serif;
      font-size: 3.75rem;
      font-weight: 600;
      line-height: 4.625rem;
      letter-spacing: 0px;
      margin-top: 10px;
      margin-bottom: 15px;
      transition: all 0.5s ease-in-out;
  
  }
  
  .service-wrapper h1 span {
    background: linear-gradient(90deg, #43FFEC 0%, #FFB84D 50%, #FF0080 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: var(--transition-base);
  }

/* carousel wrapper end*/


/* bottom wrapper */

.bottom-wrapper {

  background-size: cover !important;
  background-position: center !important;

}

.bottom-wrapper .bottom-box {
  padding: 105px 0 135px;
  text-align: center;
}

.bottom-wrapper .bottom-box h5.sub-title {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 1px;
  margin-bottom: 5px;
}


.bottom-wrapper .bottom-box h2 {
  color: #1b1b1b;
  font-size: 5rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 10px;
}


.bottom-wrapper .bottom-box h2 a {
  color: #ffffff !important;
  font-size: 3rem;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}


.bottom-wrapper .bottom-box h2.the-title span {
  color: #da0d2b;
  border-bottom: 3px solid #da0d2b;
}

/* bottom wrapper end */


/* partner wrapper */

.partner-wrapper {}

.partner-wrapper h1 {
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.5em;
  margin-bottom: 10px;
}




.partner-wrapper .content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.partner-wrapper .content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.partner-wrapper .content:hover .content-overlay {
  opacity: 1;
}

img.content-image {
  height: 140px;
  width: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.partner-wrapper .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.partner-wrapper .content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.partner-wrapper .content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.partner-wrapper .content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}


/*  */

.service-wrapper .slick-slide {
  padding: 5px;
}




/* top wrapper */
.top-wrapper {
  height: 600px;
  background-size: cover;
  background-position: right -326px;
  background-repeat: no-repeat;
}

.top-wrapper h3 {
  font-size: 74px;
  font-weight: 800;
}


.top-wrapper h3 span {
  font-size: 40px;
  font-weight: 500;
}

/* top wrapper end */


/* gallery wrapper */

.gallery-wrapper {
  background-color: #000 !important;
}


.gallery-wrapper img {
  /* border-radius: 10px;
  margin-bottom: 20px; */
}

.gallery-wrapper p {
  font-family: 'Dancing Script', sans-serif;
  font-size: 24px;
}

.gallery-wrapper h1 {
  position: relative;
    z-index: 1;
    color: #fff;
    font-family: "DM Sans", sans-serif;
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 4.625rem;
    letter-spacing: 0px;
    margin-top: 10px;
    margin-bottom: 15px;
    transition: all 0.5s ease-in-out;

}

.gallery-wrapper h1 span {
  background: linear-gradient(90deg, #43FFEC 0%, #FFB84D 50%, #FF0080 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition-base);
}

/* gallery wrapper end */


/* gallery slider */

.slider-wrapper {
  background-color: #000 !important;
}

.slider-wrapper .carousel-item {
  height: 500px;
}

.slider-wrapper .slider-img {
  margin-bottom: 15px;
  height: 476px;
  background-size: cover !important;
  background-position: top;
  overflow: hidden !important;


}

.slider-wrapper .slider-img2 {
  margin-bottom: 15px;
  height: 230px;
  background-size: cover !important;
  background-position: top;
  overflow: hidden !important;
  visibility: visible;


}

.slider-wrapper .slider-img3 {
  margin-bottom: 15px;
  height: 230px;
  background-size: cover !important;
  overflow: hidden !important;
  background-color: #eee;
  text-align: center;
  align-items: center;
  visibility: visible;
  padding: 15px;
}

.slider-wrapper .slider-img3 h5 {
  /* font-family: "Pacifico", cursive; */
  font-weight: 700;
  font-size: 30px;
  color: #6f6f6f;
}

.slider-wrapper .slider-img3 p {
  font-weight: 500;
  font-size: 13px;
  color: #6f6f6f;
}

.slider-wrapper .slider-img3 a {
  color: #000;
  border: 1px solid #000;
  background: transparent;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.slider-wrapper .slider-img3 a:hover {
  color: #fff;
  border: 1px solid #000;
  background: #000;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}

.slider-wrapper .slider-img4 {
  margin-bottom: 15px;
  height: 228px;
  background-size: cover !important;
  background-position: center;
  overflow: hidden !important;


}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.two {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.three {
  -webkit-animation-delay: 1.0s;
  -moz-animation-delay: 1.0s;
  animation-delay: 1.0s;
}

.four {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 305s;
  animation-delay: 0.5s;
}

.five {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

.six {
  -webkit-animation-delay: 5.5s;
  -moz-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

.seven {
  -webkit-animation-delay: 6.5s;
  -moz-animation-delay: 6.5s;
  animation-delay: 6.5s;
}

.eight {
  -webkit-animation-delay: 7.5s;
  -moz-animation-delay: 7.5s;
  animation-delay: 7.5s;
}

/*=== Animations start here  ===*/


/*==== FADE IN RIGHT ===*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}


/*=== FADE IN RIGHT Big ===*/
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



/* gallery slider end */


/* about us hude */
.about-us-hude {
  position: relative;
  background-color: #000;
}

.about-us-hude .page-header-content {
  position: relative;
  z-index: 2;
}

.about-us-hude .heading {
  position: relative;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 0px 20px 35px 20px;
  color: #ffffff;
  display: inline-block;
}


.about-us-hude .icon {
  position: absolute;
  font-size: 16px;
  color: #ffffff;
  width: 100%;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
}


.about-us-hude::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 31, 0.63);
}


.about-us-hude .icon:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 40%;
  height: 2px;
  background: transparent;
  border-bottom: 1px solid #999;
}

.about-us-hude .icon:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 40%;
  height: 2px;
  background: transparent;
  border-bottom: 1px solid #999;
}

.about-us-hude p {
  font-family: 'Playfair Display', serif;
  font-size: 13px;
  font-style: italic;
  color: #ffffff;
  letter-spacing: 0.5px;
  line-height: 26px;
}


.about-info-wrapper ul {
margin-top: 30px;
}


.about-info-wrapper h4 {
  font-size: 17px;
}

.about-info-wrapper p {
  font-size: 13px;
}

.paragraph-quote{
  color:white
}
/* about us hude end*/


/* services */


.service-wrapper-new ul {
  padding-left: 0px;
}

.service-wrapper-new ul li {
  color: #fff;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 20px;
  font-size: 14px;
}

.service-wrapper-new ul li::before {
  color: #fff;
  content:'✓';
  padding-right: 10px;

}

.service-wrapper-new .card:nth-child(1) {
  background: linear-gradient(to right, rgb(248, 87, 166), rgb(255, 88, 88));
}


.service-wrapper-new .card img {
  position: absolute;
    opacity: 0.4;
    z-index: 0;
    object-fit: contain;
    bottom: 0;
    width: 55%;
    right: 0;
}

.service-wrapper-new .bg-dark-img img {
  height: 497px;
  border-radius: 10px;
  object-fit: cover;
}

.service-button button,a{
  margin-right: 1rem;
}

/* services end */


/* our work process */

.our-process {
  display: flex;
  gap: 0px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}


/* our work process */




.swiper-slide {
  position: relative;

}

.swiper-slide img {
  width: 300px !important;
  height: 400px !important;
  object-fit: cover;
  
}

.swiper-slide .slider-content {
    background: linear-gradient(180deg, transparent -4.82%, #000000 87.02%);
    border-radius: 6px;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 300px;
    height: auto;
    padding: 10px;
    padding-top: 20px;
    text-align: center;
}

.swiper-slide .slider-content h4 {
  font-weight: 600;
  font-size: 22px;
}

.swiper-slide .slider-content p {
  font-size: 16px;
}


.swiper-slide .slider-content p span {
  font-size: 26px;
  font-weight: 600;
}

.swiper-slide .slider-content a {
  background: transparent;
  color: #fff !important;
  padding: 10px;
  border: 2px solid #fff;
  margin-bottom: 20px;
}


/* service details */

.service-details h4 {
  font-size: 60px;
  background: linear-gradient(90deg, #43FFEC 0%, #FFB84D 50%, #FF0080 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--transition-base);
}


.service-details .float-right {
  float: right;
}

.service-details .float-right span {
  color: #fff;
  font-size: 14px;
}


.service-details .float-right p {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}


/* video section */

/* Hero Video */
.outter.hero-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .outter.hero-video {
    height: 325px;
  }
}

.hero-video .video-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}



@media (max-width: 767px) {
  .hero-video .video-container {
    height: 325px;
  }
}
.hero-video video {
  object-fit: contain;
  width: 100%;
  /* height: 700px; */
  /* border-radius: 30px; */
}
@media (max-width: 767px) {
  .hero-video video {
    height: 325px;
  }
}
.hero-video .video-container:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.hero-video h1 {
  text-transform: uppercase;
  margin: 0 0 1rem;
  padding: 0;
  line-height: 1;
  color: white;
}
@media (max-width: 767px) {
  .hero-video h1 {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .hero-video h1 {
    font-size: 52px;
  }
}
.hero-video .desc {
  color: white;
  font-weight: 400;
  font-size: 18px;
}
.hero-video .callout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  position: relative;
  z-index: 10;
  width: 70%;
  margin: auto;
}
@media (max-width: 767px) {
  .hero-video .callout {
    width: 90%;
  }
}
.hero-video .button {
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 0px;
  margin-top: 20px;
  background-color: #ff1919;
  padding: 15px 30px;
  border-radius: 0px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 767px) {
  .hero-video .button {
    padding: 10px 20px;
  }
}
.hero-video .button:hover {
  cursor: pointer;
  background-color: rgb(141, 33, 0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  background-color: #1f2e43 !important;
  background: radial-gradient( circle at 55% 92%, #426691 0 12%, transparent 12.2% ), radial-gradient(circle at 94% 72%, #426691 0 10%, transparent 10.2%), radial-gradient( circle at 20% max(78%, 350px), #263a53 0 7%, transparent 7.2% ), radial-gradient(circle at 0% 0%, #263a53 0 40%, transparent 40.2%), #1f2e43;
  border-radius: 10px 0 0 10px;
}

.modal-body button{
  background-color:white;
  color:rgb(52, 52, 132)
}

.modal-btn{
  display: flex;
  justify-content: center;
}

.modal-btn button{
  background-color: #75ff75;
  font-weight: bold;
}

/*Footer */
.last-div-footer{
  text-align: center;
  /* background-color: #000000; */
  padding:1rem 0;
}